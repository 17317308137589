import classnames from "classnames";
import "spinkit/spinkit.css";
import "./Spinner.style.scss";

export const Spinner = (props: { visible: boolean }) => {
  return (
    <div className={classnames("sk-wave", { hidden: !props.visible })} suppressHydrationWarning={true}>
      <div className="sk-wave-rect" suppressHydrationWarning={true} />
      &nbsp;
      <div className="sk-wave-rect" suppressHydrationWarning={true} />
      &nbsp;
      <div className="sk-wave-rect" suppressHydrationWarning={true} />
      &nbsp;
      <div className="sk-wave-rect" suppressHydrationWarning={true} />
      &nbsp;
      <div className="sk-wave-rect" suppressHydrationWarning={true} />
    </div>
  );
};

export default Spinner;
