import { GetYoutubeVideosByIdQueryVariables } from "@ihr-radioedit/inferno-webapi/";
import { inject } from "mobx-react";
import { useEffect, useState, useCallback, useRef } from "react";
import classnames from "classnames";
import type { BlockFragment } from "@ihr-radioedit/inferno-webapi";
import { Remote } from "../../components/remote/Remote.component";
import { isYouTubeChannel, isYouTubePlaylist } from "../../lib/guards";
import { isWindowDefined } from "@inferno/renderer-shared-core";
import { FauxButton } from "../../ui";
import { ButtonKind } from "../../ui";
import { formatImage, getYoutubeVideosById } from "@ihr-radioedit/inferno-core";
import Spinner from "../../ui/Spinner.component";
import styles from "./YouTubePlayer.module.scss";
import type { Store } from "@inferno/renderer-shared-core";
import { lookup } from "@ihr-radioedit/inferno-core";

export interface VideoItem {
  id: string;
  snippet: {
    title: string;
    thumbnails: {
      default: {
        url: string;
      };
    };
    videoId: {
      id: string;
    };
  };
}

export interface YouTubePlayerProps {
  store?: Store;
  block: BlockFragment;
}

const YoutubeIframe = ({ videoId, title }: { videoId: string; title?: string }) => {
  if (videoId) {
    return (
      <>
        <div className="embed-responsive embed-responsive-16by9 media-asset">
          <iframe
            title="YouTubePlayer"
            id="player"
            width="610"
            height="343"
            allowFullScreen
            src={`https://www.youtube.com/embed/${videoId}?feature=oembed%22vq=hd1080%22frameborder="0"%22allow="accelerometer;autoplay;encrypted-media;gyroscope;picture-in-picture"`}
          />
        </div>
        {title ? <h3 className={styles.videoTitle}>{title}</h3> : null}
      </>
    );
  }

  return <Spinner visible={true} />;
};

export const YouTubePlayer = inject("store")(({ block, store }: YouTubePlayerProps) => {
  if (!store || store.disableThirdParty || !isYouTubePlaylist(block) || !isYouTubeChannel(block)) {
    return null;
  }

  const { site, env } = store;
  if (!site.sections.design?.station_logo || !site.sections.general) {
    return null;
  }

  const defaultVideoId = block.default_video_id || "";

  const stationLogo = formatImage(site.sections.design.station_logo.id, env.IMAGE_HOST);
  const [playerVideoId, setVideoId] = useState(defaultVideoId);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoList, setVideoList] = useState([]);
  const [youtubeLink, setYoutubeLink] = useState("");
  const playerWrapper = useRef<HTMLDivElement | null>(null);

  const initPlaylist = useCallback(
    (playlist, items, video, title) => {
      setVideoId(defaultVideoId || video.id);
      setVideoTitle(!defaultVideoId ? title : null);
      setVideoList(items);
      setYoutubeLink(`playlist?list=${playlist.id}`);
    },
    [defaultVideoId],
  );

  const initChannel = useCallback(
    (channel, items, video) => {
      setVideoId(defaultVideoId || video.id);
      setVideoList(items);
      setYoutubeLink(`channel/${channel.id}`);
    },
    [defaultVideoId],
  );

  useEffect(() => {
    store.storeBlock({ ...block });
    if (isWindowDefined()) {
      if (block.playlist) {
        const { playlist } = block;
        const { items } = playlist?.youtube?.playlist?.playlistItems ?? { items: [] };
        const { snippet } = items[0] ?? null;
        const { videoId, title } = snippet ?? { videoId: null, title: null };
        initPlaylist(playlist, items, videoId, title);
      }

      if (block.channel) {
        const { channel } = block;
        const { items } = channel.youtube?.channel?.uploadsPlaylist?.youtube?.playlist?.playlistItems ?? {
          items: [],
        };

        const { snippet } = items[0] ?? null;
        const { videoId } = snippet ?? { videoId: null, title: null };
        initChannel(channel, items, videoId);
      }
    }
  }, [block, initPlaylist, initChannel, store]);

  const changeVideo = (newVideoId: any, newVideoTitle: string) => {
    setVideoId(newVideoId);
    const page = store?.page.currentPage;
    if (page && isWindowDefined()) {
      const referrer = window.location.href;

      store?.onAnalyticsAction.dispatch({
        sectionName: "youtube",
        pageName: page.name,
        context: `${newVideoTitle}`,
        action: "item_selected",
        url: "",
        referrer,
      });
    }
  };

  const videoClass = classnames(styles.video, {
    [styles.max6]: !!playerWrapper.current && playerWrapper.current.clientWidth >= 1060,
    [styles.max8]: !!playerWrapper.current && playerWrapper.current.clientWidth < 1060,
  });

  const playlistWrapperClass = classnames(styles.playlistWrapper, {
    [styles.wideColumn]: !!playerWrapper.current && playerWrapper.current.clientWidth >= 1060,
  });

  return (
    <div className={styles.youtubePlayer} ref={playerWrapper}>
      <div className={styles.wrapper} style={{ borderTop: "none" }}>
        <header>
          <h2 className={styles.blockTitle}>{block.value.title}</h2>
        </header>
        <div className={videoClass}>
          {block.channel ? (
            <Remote
              loader={async () => {
                const query: GetYoutubeVideosByIdQueryVariables = {
                  lookup,
                  slug: site.index.slug,
                  name: store?.page.currentPage?.name ?? "",
                  blockId: block.id,
                  videoIds: [playerVideoId],
                };

                return getYoutubeVideosById(query);
              }}
              cacheKey={`youtube-${block.id}-${playerVideoId}`}
              showLoading={true}
            >
              {({ data }) => {
                const [video] = data?.videos ?? [];
                return video ? (
                  <YoutubeIframe videoId={video.id} title={video.title ?? ""} />
                ) : (
                  <Spinner visible={true} />
                );
              }}
            </Remote>
          ) : (
            <YoutubeIframe videoId={playerVideoId} title={videoTitle} />
          )}
        </div>
        <div className={playlistWrapperClass}>
          <ul className={styles.playlist}>
            {!!videoList &&
              videoList.map((video: VideoItem, index: number) => (
                <li
                  key={video.id}
                  className={`${styles.thumbnail} ${video.snippet.videoId.id === playerVideoId ? styles.active : ""}`}
                  onClick={() => changeVideo(video.snippet.videoId.id, video.snippet.title)}
                  tabIndex={-Math.abs(index)}
                >
                  {!!video.snippet.thumbnails.default ? (
                    <img
                      src={video.snippet.thumbnails.default.url}
                      alt={video.snippet.title + "-thumbnail"}
                      className={styles.thumbnail__image}
                    />
                  ) : (
                    <div className={styles.placeholder}>
                      <img
                        src={stationLogo}
                        alt={video.snippet.title + "-thumbnail"}
                        className={styles.thumbnail__image}
                        height="100%"
                      />
                    </div>
                  )}
                  <p className={styles.thumbnail__title}>{video.snippet.title}</p>
                </li>
              ))}
            <li className={styles.buttonWrapper}>
              {!!youtubeLink && (
                <FauxButton
                  kind={ButtonKind.SECONDARY}
                  to={`https://www.youtube.com/${youtubeLink}`}
                  size="small"
                  target="_blank"
                  className={styles.button}
                >
                  See All
                </FauxButton>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
});

export default YouTubePlayer;
